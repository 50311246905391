.pswp__top-bar__container {
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 0 1.25rem;
    height: 4.5rem;
    width: 100%;
}

.pswp__container {
	.pswp-small-logo img {
		height: 96px !important;
		max-height: 96px !important;
		min-height: 96px !important;
	}
}

.nav-side-nav-toggle {
	.mdi-menu {
		margin-right: -11px;
	}
}

.slick-arrow::before {
	color: $primary-color;
}

.slick-slider {
	.slick-prev.is--hovered {
		background: linear-gradient(to right, #ffffff9a 0%,#ffffff00 100%); 
	}
	.slick-next.is--hovered {
		background: linear-gradient(to left, #ffffff9a 0%,#ffffff00 100%); 
	}
}

.breaker__container {
	.card-image::after {
		background: linear-gradient(to bottom, #00000000 0%,#0000009f 100%); 
		position: absolute;
		content: "";
		height: 180px;
		width: 100%;
		bottom: 0;
		left: 0;
	}
	.breaker__headline {
		text-shadow: none !important;
	}
}

.sidenav .sidenav-close {
	background-color: #868686;
}

.socials {
	justify-content: flex-start !important;
    &__link {
		margin: 0 0.625rem !important;
	}	
}

.footer-sitemap ul li:first-child {
    font-weight: bold;
}

.sidenav__navigation {
	ul {
		padding-top: 0.25rem;
	}
}

ul.sidenav__navigation > li {
    padding-top: 0.25rem;
}

ul.sidenav__navigation li > a {
    font-size: 1.5rem;
}

ul.sidenav__navigation ul li > a {
    font-size: 1.2rem;
}


// border radius fixes

.news-teaser, .infobox__container, .infobox, .breaker__container, .card, .breaking-news, .voting__list__answer  {
	border-radius: 3px;
	overflow: hidden;
}

.current-program {
	border-radius: 0px;
}

.pswp-small-logo__img {
	transform: translate(-8px, -1px);
	height: 3.375rem;
	max-height: none;
}

.webradio-cover > img {
    border-radius: 3px;
}

.ad__wrapper:not(:empty)::before {
    font-size: 1.2rem;
}

.ad__wrapper--autoSection::before {
    top: 1rem;
}

// RNRWA-806 - Westfunk: Schriftgrößen anpassen

p {
	line-height: 1.55rem;
	font-size: 1.1rem;

	@include media-breakpoint-up(m) {
		font-style: normal;
		line-height: 1.96rem;
		font-size: 1.2rem;
	}
}

small {
	font-size: 1rem;
}

h6 {
	font-size: 1.1rem;
	font-weight: bold;

	@include media-breakpoint-up(m) {
		font-size: 1.2rem;
	}
}

a,li {
	line-height: 1.55rem;
	font-size: 1.1rem;

	@include media-breakpoint-up(m) {
		line-height: 1.96rem;
		font-size: 1.2rem;
	}
}

.article__details .article__teaser {
	line-height: 1.55rem;
	font-size: 1.1rem;
	font-style: normal; 
	font-weight: bold;

	@include media-breakpoint-up(m) {
		line-height: 1.96rem;
		font-size: 1.2rem;
	}
}

.breadcrumb {
	@include media-breakpoint-down(m) {
		display: none;
	}

	@include media-breakpoint-up(m) {
		line-height: 1.96rem;
		font-size: 1.2rem;
	}

	line-height: 1.55rem;
	font-size: 1.1rem;

	&::before {
		line-height: 1.5;
	}
}

.timeline .timeline__item .timeline__datetime {
	.timeline__date {
		font-size: 1rem;
	}
}

.team-cards .team-card {
	.team-card__team {
		font-size: 1rem;
	}
	.team-card__tag {
		font-size: 1rem;
	}
	.card__name {
		font-size: 1.1rem;
		font-weight: bold;
	}

	@include media-breakpoint-down(m) {
		.card__name {
			font-size: 1.2rem;
		}	
	}
}

.infobox__container .tabs__wrapper .tabs.tabs--vertical .tab .tab__title {
	font-size: 1rem;
}

.news-categories__columns .news-categories__column .news__item .news-categories__content .news__title {
	font-weight: bold;
}

@media (min-width: 48em) {
	.news-slider .news-teaser__content {
		height: 19rem;
	}
}

@media (min-width: 48em) {
	.news-slider .slick-dots {
		bottom: calc(19rem - 40px);
	}
}

@media (min-width: 48em) {
	.infobox__container {
		min-height: 32.95rem;
	}
	.current-program__info {
		margin-top: 1.75rem;
	}
}

.breaking-news {
	&__attention, &__content {
		line-height: 1.55rem;
		font-size: 1.1rem;
	
		@include media-breakpoint-up(m) {
			line-height: 1.96rem;
			font-size: 1.2rem;
		}
	}
}

.btn.btn-primary {
	font-family: "eurostile-condensed", sans-serif;

	line-height: 1.55rem !important;
	font-size: 1.1rem !important;

	@include media-breakpoint-up(m) {
		line-height: 1.96rem !important;
		font-size: 1.2rem !important;
	}
}

.article__date {
	line-height: 1.55rem;
	font-size: 1.1rem;

	@include media-breakpoint-up(m) {
		line-height: 1.96rem;
		font-size: 1.2rem;
	}
}

.playlist__song__infos .small {
	font-size: 1rem !important;
}

.webradio-title {
	span {
		line-height: 1.55rem !important;
		font-size: 1.1rem !important;
	
		@include media-breakpoint-up(m) {
			line-height: 1.96rem !important;
			font-size: 1.2rem !important;
		}
	}
}

form,.input-field input,label,li,span {
	font-family: "eurostile-condensed", sans-serif !important;
	line-height: 1.55rem !important;
	font-size: 1.1rem !important;

	@include media-breakpoint-up(m) {
		line-height: 1.96rem !important;
		font-size: 1.2rem !important;
	}
}

.traffic-tabs__row__text, .traffic-tabs__row__text small {
	line-height: 1.55rem !important;
	font-size: 1.1rem !important;

	@include media-breakpoint-up(m) {
		line-height: 1.96rem !important;
		font-size: 1.2rem !important;
	}
}

.tabs .tab {
	display: flex !important;
    justify-content: center !important;
	align-items: center !important;
	
	line-height: 1.55rem !important;
	font-size: 1.1rem !important;

	@include media-breakpoint-up(m) {
		line-height: 1.96rem !important;
		font-size: 1.2rem !important;
	}
}

.infobox__container .tab__title {
	max-width: 80% !important;
    margin-left: 10% !important;
    white-space: normal !important;
}

.breaker .breaker__headline__link {
	font-size: 1.6875rem;
	
	@include media-breakpoint-up(m) {
		font-size: 2.25rem;
	}
}

.weather-info__box__extended * {
	line-height: 1.55rem;
	font-size: 1.1rem;

	@include media-breakpoint-up(m) {
		line-height: 1.96rem;
		font-size: 1.2rem;
	}
}

form input.nav-search-bar__text {
	font-family: "eurostile-condensed", sans-serif !important;
	line-height: 1.55rem;
	font-size: 1.1rem;

	@include media-breakpoint-up(m) {
		line-height: 1.96rem;
		font-size: 1.2rem;
	}
}

.street-symbol--highway::after {
	margin-top: -1px;
}

li.tab {
	a {
		font-family: "eurostile-condensed", sans-serif !important;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		display: flex !important;
		line-height: 1.55rem !important;
		font-size: 1.1rem !important;
		flex-wrap: wrap;
		padding: 0 !important;
	
		@include media-breakpoint-up(m) {
			line-height: 1.96rem !important;
			font-size: 1.2rem !important;
		}
	}
}

.infobox__container span.tab__title {
	margin-right: 12px !important;	
	margin-left: 12px !important;	
	
}

.tabs--vertical .tab a.active::before {
    position: absolute;
    bottom: 0;
}

// ÄNDERUNGEN VOM 13.0.2020

.nav-search-bar .nav-search-bar__text {
	font-size: 1.2rem !important;
}

.webradio-title {
	justify-content: center !important;
	
	span {
		line-height: 1 !important;
	}
}

.tab__title {
	line-height: 1.2 !important;
}


.infobox__container .btn-send {
	justify-content: center;
	align-items: center;
	display: flex;
}

.infobox__wrapper {
	padding: 1rem;
}

span.badge--circle {
	font-size: 0.8rem !important;
	border-radius: 999px;
	min-width: auto;
	width: auto;
}

.file-field .btn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.no-iframe {
	.card-content, a, * {
		line-height: 1.55rem;
		font-size: 1.1rem;
		
		@include media-breakpoint-up(m) {
			line-height: 1.96rem;
			font-size: 1.2rem;
		}
	}
}

blockquote {
	line-height: 1.55rem;
	font-size: 1.1rem;
	
	@include media-breakpoint-up(m) {
		line-height: 1.96rem;
		font-size: 1.2rem;
	}
}

.file__item__header, .file__item__desc {
	line-height: 1.55rem;
	font-size: 1.1rem;
	
	@include media-breakpoint-up(m) {
		line-height: 1.96rem;
		font-size: 1.2rem;
	}
	
	* {
		line-height: 1.55rem;
		font-size: 1.1rem;
		
		@include media-breakpoint-up(m) {
			line-height: 1.96rem;
			font-size: 1.2rem;
		}
	}
}

.infobox__container .playlist--small {
	span {
		line-height: 1.25 !important;
	}

	.small {
		font-size: 1rem !important;	
	}	
}

.webradio--header .icon-play {
	display: flex;
    align-items: center;
    justify-content: center;
}

.datepicker, .materialize-textarea  {
	font-family: "eurostile-condensed", sans-serif !important;
}

.datepicker-container *, .datepicker-row *, .datepicker-date-display * {
	font-family: "eurostile-condensed", sans-serif !important;
}

.podlove {
	font-family: "eurostile-condensed", sans-serif !important;
}

.podlove {
	font-family: "eurostile-condensed", sans-serif !important;
}

.podlove * {
	font-family: "eurostile-condensed", sans-serif !important;
}

.input-field * {
	font-family: "eurostile-condensed", sans-serif !important;
}

.article__header p {
	font-weight: bold !important;
}

.footer__skyline {
	@include media-breakpoint-up(m) {
		width: 60%;
	}
}

.page-footer__content {
	padding-top: 30px !important;
}

.footer__skyline {
    margin-bottom: -40px !important;
}

// Social Media Links Header
nav .nav-social-media-links {
	display: none;
	padding-left: rem(14px);

	@include media-breakpoint-up(xl) {
		display: flex !important;
	}


	a {
		margin-left: 1.125rem;
		position: relative;
		overflow: hidden;
		display: block;
		height: 3rem;
		width: 3rem;

		.mdi {
			height: 3rem;
			width: 3rem;
			line-height: 3rem;
			font-size: 2.1875rem;
		}
	}
}

.nav .nav-ivw {
	height: 55px;
	margin: 0 8px 0 0;

	@include media-breakpoint-up(m) {
		margin: 0 4rem 0 0;
	}
}

// form overflow fix 6.9.2021

.quantyoo-form .card {
	overflow: unset !important;
}

.article__details {
	overflow: unset !important;
} 